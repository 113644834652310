import { merge } from 'lodash';
import { Theme } from '@mui/material/styles';

// project imports
import Button from './Button';
import ButtonBase from './ButtonBase';
import IconButton from './IconButton';
import Alert from './Alert';
import ListItemIcon from './ListItemIcon';
import CssBaseline from './MuiCssBaseline';
import CardContent from './CardContent';
import Dialog from './Dialog';
import DialogContentText from './DialogContentText';
import DialogTitle from './DialogTitle';
import OutlinedInput from './OutlinedInput';
import Tab from './Tab';
import TableBody from './TableBody';
import TableCell from './TableCell';
import TableHead from './TableHead';
import TablePagination from './TablePagination';
import TableRow from './TableRow';
import Tabs from './Tabs';
import PaginationItem from './PaginationItem';
import Chip from './Chip';

// ==============================|| OVERRIDES - MAIN ||============================== //

export default function componentsOverrides(theme: Theme) {
  return merge(
    Button(theme),
    ButtonBase(),
    Dialog(),
    Alert(theme),
    DialogContentText(theme),
    DialogTitle(),
    Chip(),
    IconButton(theme),
    ListItemIcon(theme),
    CssBaseline(),
    CardContent(),
    OutlinedInput(theme),
    Tab(theme),
    TableBody(theme),
    TableCell(theme),
    PaginationItem(theme),
    TableHead(theme),
    TablePagination(),
    TableRow(),
    Tabs()
  );
}
