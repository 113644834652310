// ==============================|| OVERRIDES - TABLE ROW ||============================== //

export default function TableRow() {
  return {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:not(:last-of-type)': {
            boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.03)',
            '& .MuiTableCell-root': {
              borderBottom: '2px solid #EEF1F4',
              borderTop: '2px solid #EEF1F4',

              '&:last-of-type': {
                paddingRight: 24,
                borderTopRightRadius: 8,
                borderBottomRightRadius: 8,
                borderRight: '2px solid #EEF1F4'
              },
              '&:first-of-type': {
                paddingLeft: 24,
                borderTopLeftRadius: 8,
                borderBottomLeftRadius: 8,
                borderLeft: '2px solid #EEF1F4'
              }
            }
          }
        }
      }
    }
  };
}
