// project imports
import { AppDispatch } from '..';
import { axios } from '../../_api/axios';
import { userActions } from '../slices/user';

export function getProfile() {
  return async (dispatch: AppDispatch) => {
    dispatch(userActions.startLoading());
    try {
      const response = await axios.get('/api/Profile');
      dispatch(userActions.getProfileSuccess(response.data));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(userActions.endLoading());
    }
  };
}

export function getTimesheetNotification() {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.get('/api/Profile/notifications');
      dispatch(userActions.getTimesheetNotificationSuccess(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function readNotifications(data: number[]) {
  return async (dispatch: AppDispatch) => {
    try {
      await axios.put('/api/Profile/notifications', { notificationIds: data });
      dispatch(getTimesheetNotification());
    } catch (error) {
      console.log(error);
    }
  };
}
