import { Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - ALERT ||============================== //

export default function Alert(theme: Theme) {
  return {
    MuiAlert: {
      styleOverrides: {
        root: {
          color: '#fff',
          fontSize: '0.875rem',
          boxShadow:
            '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)'
        },
        icon: {
          fontSize: '1rem'
        },
        message: {
          // padding: 0,
        },
        filled: {
          color: '#fff'
        },
        border: {
          padding: '10px 16px',
          border: '1px solid'
        },
        action: {
          '& .MuiButton-root': {
            padding: 2,
            height: 'auto',
            fontSize: '1rem'
          },
          '& .MuiIconButton-root': {
            width: 'auto',
            height: 'auto',
            color: '#fff',
            marginRight: 6,
            marginBottom: 3,
            fontSize: '0.875rem',
            '& .MuiSvgIcon-root': {
              fontSize: '1rem'
            }
          }
        }
      }
    }
  };
}
