import { accessTokenRequest, msalInstance } from '../authConfig';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

export const login = async () => {
  try {
    await msalInstance.loginRedirect(accessTokenRequest);
  } catch (err) {
    console.log(err);
  }
};

export const getAccount = () => {
  const accounts = msalInstance.getAllAccounts();
  // If no account is logged in, redirect the user to log in.
  if (accounts === undefined || accounts.length === 0) {
    login();
  }
  return msalInstance.getActiveAccount() || msalInstance.getAllAccounts()[0];
};

export const checkAuth = async () => {
  await msalInstance.handleRedirectPromise();
  const account = getAccount();
  try {
    const accessToken = await msalInstance.acquireTokenSilent({ ...accessTokenRequest, account });
    return accessToken;
  } catch (error) {
    // if our access / refresh / id token is expired we need redirect to AAD to get a new one.
    if (error instanceof InteractionRequiredAuthError) {
      await msalInstance.acquireTokenRedirect({ ...accessTokenRequest, account });
    }
  }
};
