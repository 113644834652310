import { TypographyVariantsOptions } from '@mui/material/styles';

// project imports
import config from '../config';

// ==============================|| DEFAULT THEME - TYPOGRAPHY  ||============================== //

const Typography = (): TypographyVariantsOptions => ({
  htmlFontSize: 16,
  fontFamily: config.fontFamily,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontWeight: 700,
    fontSize: '2rem',
    lineHeight: '1.5em'
  },
  h2: {
    fontWeight: 700,
    fontSize: '1.5rem',
    lineHeight: 1.33
  },
  h3: {
    fontWeight: 700,
    fontSize: '1.25rem',
    lineHeight: 1.4
  },
  h4: {
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: 1.5
  },
  h5: {
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: 1.5
  },
  subtitle1: {
    fontSize: '1.25rem',
    fontWeight: 400,
    lineHeight: 1.4
  },
  body1: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.5
  },
  body2: {
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: 1.5
  },
  caption: {
    fontWeight: 700,
    fontSize: '0.875rem',
    lineHeight: 1.57
  }

  // Not used Typography
  // h6: {
  //   fontWeight: 400,
  //   fontSize: '0.875rem',
  //   lineHeight: 1.57
  // },
  // subtitle2: {
  //   fontSize: '0.75rem',
  //   fontWeight: 500,
  //   lineHeight: 1.66
  // },
  // overline: {
  //   lineHeight: 1.66
  // },
  // button: {
  //   textTransform: 'capitalize'
  // }
});

export default Typography;
