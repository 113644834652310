// ==============================|| OVERRIDES - CHIP ||============================== //

export default function Chip() {
  return {
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          backgroundColor: '#fff',
          fontSize: '16px',
          color: '#333333',
          height: '40px',
          '&:active': {
            boxShadow: 'none'
          }
        }
      }
    }
  };
}
