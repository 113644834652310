import { uk } from 'date-fns/locale';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { Provider as ReduxProvider } from 'react-redux';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// project imports
import App from './App';
import './assets/react-table.css';
import { setupStore } from './store';
import { msalInstance } from './authConfig';
import { ConfigProvider } from './contexts/ConfigContext';

const store = setupStore();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <ReduxProvider store={store}>
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={uk}>
      <ConfigProvider>
        <BrowserRouter>
          <MsalProvider instance={msalInstance}>
            <App />
          </MsalProvider>
        </BrowserRouter>
      </ConfigProvider>
    </LocalizationProvider>
  </ReduxProvider>
);
