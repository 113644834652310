// material-ui
import { Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - TABLE CELL ||============================== //

export default function TableCell(theme: Theme) {
  return {
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          padding: 18,
          fontWeight: 400,
          fontFamily: 'Gilroy',
          color: '#0F1621'
        },
        sizeSmall: {
          padding: 8
        },
        head: {
          fontSize: '1rem',
          fontWeight: 700,
          fontFamily: 'Gilroy',
          color: '#FFFFFF'
        },
        footer: {
          fontSize: '0.75rem',
          textTransform: 'uppercase',
          fontFamily: 'Gilroy'
        }
      }
    }
  };
}
