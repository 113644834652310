import { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Drawer as MainSideBar, useMediaQuery } from '@mui/material';

// project imports
import config from '../../config';
import MiniSideBar from './MiniSideBar';
import SideBarContent from './SideBarContent';
import { openSideBar } from '../../store/slices/menu';
import { useDispatch, useSelector } from '../../store';

interface Props {
  window?: () => Window;
}

const SideBar = ({ window }: Props) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  const dispatch = useDispatch();
  const { sideBarOpen } = useSelector((state) => state.menu);

  const container = window !== undefined ? () => window().document.body : undefined;
  const sideBarContent = useMemo(() => <SideBarContent open={sideBarOpen} />, [sideBarOpen]);

  return (
    <Box component="nav" sx={{ flexShrink: { md: 0 }, zIndex: 1200 }} aria-label="mailbox folders">
      {!matchDownMD ? (
        <MiniSideBar variant="permanent" open={sideBarOpen}>
          {sideBarContent}
        </MiniSideBar>
      ) : (
        <MainSideBar
          container={container}
          variant="temporary"
          open={sideBarOpen}
          onClose={() => dispatch(openSideBar(!sideBarOpen))}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: config.sideBarWidth,
              borderRight: `1px solid #0F3D5C`,
              backgroundImage: 'none',
              boxShadow: 'inherit',
              backgroundColor: '#0F3D5C',
              color: '#FFFFFF'
            }
          }}
        >
          {sideBarContent}
        </MainSideBar>
      )}
    </Box>
  );
};

export default SideBar;
