import { SxProps } from '@mui/system';
import { Link } from 'react-router-dom';
import { ButtonBase } from '@mui/material';

// project imports
import Logo from './LogoMain';
import LogoIcon from './LogoIcon';
import config from '../../config';

interface Props {
  isIcon?: boolean;
  sx?: SxProps;
}

const LogoSection = ({ isIcon, sx }: Props) => (
  <ButtonBase disableRipple component={Link} to={config.defaultPath} sx={sx}>
    {isIcon ? <LogoIcon /> : <Logo />}
  </ButtonBase>
);

export default LogoSection;
