import { useMemo } from 'react';

// project imports
import Scrollbar from './Scrollbar';
import Navigation from './Navigation';
import SideBarHeader from './SideBarHeader';
import { useSelector } from '../../../store';

interface Props {
  open: boolean;
}

const SideBarContent = ({ open }: Props) => {
  const { userProfile } = useSelector((state) => state.user);
  const sideBarHeader = useMemo(() => <SideBarHeader open={open} user={userProfile} />, [open, userProfile]);
  const navigation = useMemo(() => <Navigation open={open} />, [open]);

  return (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': {
          height: '100%'
        }
      }}
    >
      {sideBarHeader}
      {navigation}
    </Scrollbar>
  );
};

export default SideBarContent;
