const LogoMain = ({ reverse, ...others }: { reverse?: boolean }) => {
  return (
    <svg width="47" height="50" fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159.82 202.58">
      <polygon points="159.82 202.57 159.82 158.23 112.24 158.23 112.24 202.57 126.22 202.57 126.22 169.95 145.83 169.95 145.83 202.57 159.82 202.57" />
      <polygon points="106.88 158.23 90.96 158.23 82.05 169.78 73.14 158.23 57.22 158.23 74.09 180.04 56.63 202.57 72.54 202.57 82.05 190.31 91.56 202.57 107.48 202.57 90.01 180.04 106.88 158.23" />
      <polygon points="51.86 202.57 51.86 158.23 38.24 158.23 25.93 175.72 13.62 158.23 0 158.23 0 202.57 13.38 202.57 13.38 178.15 25.29 194.96 26.58 194.96 38.48 178.15 38.48 202.57 51.86 202.57" />
      <path d="M148.29,114.14l-34-34V56.68l34-34L125.54,0,92.88,32.67l-13-13h0l-13,13L34.22,0,15.08,19.13,11.52,22.7l34,34V80.1l-34,34,22.7,22.7L45.51,125.5v17.11h68.76V125.52l11.32,11.32ZM31.87,103.2,38,97l13.24,13.24-6.16,6.16Zm8.51,18-6.16,6.16L21,114.09l6.16-6.16Zm17.49-70,8.21,8.2L57,68.41l9.07,9.07L57.9,85.67,52.2,80V56.81Zm22,3.86,4.35,4.35-4.34,4.34-4.35-4.35Zm27.7,1.83V80.05l-5.67,5.67-8.24-8.24,9.08-9.07-9.06-9.06,8.2-8.21ZM97.65,99.44,110.89,86.2l6.16,6.16L103.81,105.6Zm-4.37-31-4.34,4.34-4.32-4.33L89,64.08Zm-13.4,13.4-4.32-4.33,4.33-4.33,4.32,4.33ZM75.15,68.42l-4.33,4.33-4.34-4.34,4.33-4.33Zm-13,31L56,105.55,42.76,92.31l6.16-6.16Zm9.45,0-9-9,8.19-8.2,9.05,9.05,9.07-9.05,8.24,8.24-9,9,18.6,18.6H53Zm36.93,10.93,13.24-13.24,6.16,6.16L114.7,116.49ZM127.9,33.59l-6.16,6.16L108.5,26.51l6.16-6.16Zm-8.51-18,6.15-6.16L138.78,22.7l-6.15,6.16ZM103.77,31.24,117,44.48l-6.16,6.16L97.61,37.4ZM97.16,46.41,89,54.62l-9.08-9.08-9.07,9.07-8.21-8.2L79.88,29.13ZM48.92,50.64l-6.16-6.16L56,31.24l6.16,6.15ZM45.11,20.35l6.16,6.15L38,39.75l-6.16-6.16Zm-18,8.51L21,22.7,34.22,9.46l6.16,6.16Zm25.06,107V124.72h55.38v11.15Zm73.39-8.49-6.16-6.16L132.67,108l6.16,6.15Z" />
    </svg>
  );
};

export default LogoMain;
