import { To } from 'history';
import { ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';
import { Box, Button, ListItem, useMediaQuery } from '@mui/material';

// project imports
import { useDispatch } from '../../../store';
import { openSideBar } from '../../../store/slices/menu';

interface Props {
  to: To;
  icon?: ReactNode;
  title: ReactNode | string;
  open: boolean;
}

const NavigationItem = ({ to, icon, title, open }: Props) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  const isSelected = to === pathname ? true : false;

  const itemHandler = () => {
    if (matchDownMD) dispatch(openSideBar(false));
  };

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        justifyContent: 'center',
        mb: open ? 1 : 1,
        py: 0,
        px: 2
      }}
    >
      <Button
        component={Link}
        startIcon={icon}
        disableRipple
        to={to}
        onClick={itemHandler}
        sx={{
          minWidth: '42px',
          borderRadius: 2,
          color: '#D1D5DB',
          justifyContent: open ? 'flex-start' : 'center',
          py: '9px',
          pr: open ? '8px' : 0,
          pl: open ? `24px` : 0,
          textAlign: open ? 'left' : 'center',
          textTransform: 'none',
          width: '100%',
          fontWeight: '600',
          ...(isSelected && {
            backgroundColor: '#184868',
            color: '#ECDAB2'
          }),
          '& .MuiButton-startIcon': {
            color: isSelected ? '#ECDAB2' : '#D1D5DB',
            fontSize: open ? '22px' : '24px',
            mr: open ? '8px' : '-4px'
          },
          '&:hover': {
            backgroundColor: '#184868',
            color: isSelected ? '#ECDAB2' : '#D1D5DB',

            '& .MuiButton-startIcon': {
              color: isSelected ? '#ECDAB2' : '#D1D5DB'
            }
          }
        }}
      >
        {open && (
          <Box sx={{ flexGrow: 1, fontFamily: 'Gilroy', fontSize: '16px', lineHeight: 2, fontWeight: 700 }}>
            {title}
          </Box>
        )}
      </Button>
    </ListItem>
  );
};

export default NavigationItem;
