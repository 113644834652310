import { Box } from '@mui/material';
import { List } from '@mui/material';

// project imports
import { useSelector } from '../../../store';
import NavigationItem from './NavigationItem';
import { NavItemGroup } from '../../../models/menu';
import menuItem from '../../../components/menu-items';

interface Props {
  open: boolean;
}

const Navigation = ({ open }: Props) => {
  const { userProfile: user } = useSelector((state) => state.user);

  const navGroups: NavItemGroup = user?.isAdmin ? menuItem.admin : menuItem.master;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <List disablePadding>
        {navGroups.children.map((item) => (
          <NavigationItem open={open} icon={item.icon} key={item.id} to={item.url} title={item.title} />
        ))}
      </List>
    </Box>
  );
};

export default Navigation;
