import { ReactNode, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Toolbar, AppBarProps, AppBar, useMediaQuery } from '@mui/material';

// project imports
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';
import { openSideBar } from '../../store/slices/menu';
import { useDispatch, useSelector } from '../../store';
import IconButton from '../../components/@extended/IconButton';

const Header = () => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  const dispatch = useDispatch();
  const { sideBarOpen } = useSelector((state) => state.menu);

  // header content
  const headerContent = useMemo(() => <HeaderContent />, []);

  // common header
  const mainHeader: ReactNode = (
    <Toolbar>
      <IconButton
        aria-label="open drawer"
        onClick={() => dispatch(openSideBar(!sideBarOpen))}
        edge="start"
        color="secondary"
        variant="light"
        sx={{ color: 'text.primary', bgcolor: sideBarOpen ? 'grey.300' : 'grey.100', ml: { xs: 0, lg: -1 } }}
      >
        {!sideBarOpen ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </IconButton>
      {headerContent}
    </Toolbar>
  );

  const appBar: AppBarProps = {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      borderBottom: `1px solid #f0f0f0`,
      zIndex: 1200
    }
  };

  return (
    <>
      {!matchDownMD ? (
        <AppBarStyled open={sideBarOpen} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

export default Header;
