import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import { useRef, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import { useMsal } from '@azure/msal-react';
import { FormattedMessage } from 'react-intl';
import { Button, Box, Divider, Typography } from '@mui/material';

// project imports
import { useSelector } from '../../../store';
import MenuPopover from '../../../components/MenuPopover';
import IconButton from '../../../components/@extended/IconButton';

export default function Profile() {
  const { userProfile: user } = useSelector((state) => state.user);
  const { instance } = useMsal();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const userName = `${user?.surname} ${user?.name}`;
  const fullName = `${user?.surname} ${user?.name} ${user?.fatherName || ''}`;
  const organization = user?.organization?.name || '';

  function stringAvatar(name: string) {
    return {
      sx: {
        height: 31,
        width: 31,
        fontSize: '0.825rem',
        color: '#232942',
        fontWeight: '600',
        backgroundColor: 'white',
        border: '2px solid #EEF1F4'
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
    };
  }

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    instance.logoutRedirect();
  };

  return (
    <>
      <IconButton
        color="secondary"
        shape="rounded"
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ml: 2,
          bgcolor: 'transparent',
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: 'rgba(22, 28, 36, 0.6)'
              // bgcolor: 'grey.300'
            }
          })
        }}
      >
        <Stack direction="row">
          <Avatar {...stringAvatar(userName)} />
        </Stack>
      </IconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: '300px' }}>
        <Box sx={{ p: 2, pb: 0 }}>
          <Typography
            variant="subtitle1"
            noWrap
            overflow="hidden"
            textOverflow="ellipsis"
            sx={{ fontFamily: 'Gilroy', fontWeight: 700, fontSize: '16px', color: '#0F3D5C' }}
          >
            {user?.name ? fullName : 'ПІБ'}
          </Typography>
          <Typography
            variant="body2"
            overflow="hidden"
            textOverflow="ellipsis"
            sx={{ color: '#6B7485', fontFamily: 'Gilroy', fontWeight: 400 }}
            noWrap
          >
            {user?.emailAddress}
          </Typography>
        </Box>

        <Divider sx={{ my: 1, borderStyle: 'dashed' }} />
        <Box sx={{ px: 2 }}>
          <Typography
            component={Link}
            to="https://mhpo365.sharepoint.com/:b:/r/sites/MHP_WorkPlace/Shared%20Documents/Instructions/%D0%86%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%86%D1%96%D1%97%20%D0%B7%20%D0%BA%D0%BE%D1%80%D0%B8%D1%81%D1%82%D1%83%D0%B2%D0%B0%D0%BD%D0%BD%D1%8F%20%D1%81%D0%B8%D1%81%D1%82%D0%B5%D0%BC%D0%BE%D1%8E%20Personnel%20Planning/Personnel_Planning.pdf?csf=1&web=1&e=SLGETd"
            target="_blank"
            variant="body2"
            sx={{ fontFamily: 'Gilroy', fontWeight: 400, fontSize: '16px', color: '#333333' }}
            noWrap
          >
            <FormattedMessage id="ProfilePopover.Item.Manual" />
          </Typography>
        </Box>
        {organization && (
          <>
            <Divider sx={{ my: 1, borderStyle: 'dashed' }} />
            <Box sx={{ px: 2 }}>
              <Typography variant="body2" sx={{ color: '#6B7485', fontFamily: 'Gilroy', fontWeight: 400 }} noWrap>
                <FormattedMessage id="ProfilePopover.Item.Organization" />
              </Typography>
              <Typography variant="body2" noWrap sx={{ fontFamily: 'Gilroy', fontWeight: 400, color: '#333333' }}>
                {organization}
              </Typography>
            </Box>
          </>
        )}
        <Divider sx={{ my: 1, borderStyle: 'dashed' }} />
        <Box sx={{ px: 2, pb: 1 }}>
          <Button
            fullWidth
            color="inherit"
            variant="text"
            onClick={handleLogout}
            sx={{
              fontFamily: 'Gilroy',
              p: 1,
              textTransform: 'none',
              fontWeight: 700,
              fontSize: '14px'
            }}
          >
            <FormattedMessage id="ProfilePopover.Btn.Exit" />
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
