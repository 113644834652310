import { FormattedMessage } from 'react-intl';
import { useRef, useState, useMemo } from 'react';
import { MenuItem, ListItemText, Box, ListItemIcon } from '@mui/material';

// project imports
import useConfig from '../../../hooks/useConfig';
import MenuPopover from '../../../components/MenuPopover';
import enFlag from '../../../assets/images/icons/enFlag.svg';
import ukFlag from '../../../assets/images/icons/ukFlag.svg';
import IconButton from '../../../components/@extended/IconButton';

const allLang = [
  {
    label: <FormattedMessage id="LanguagePopover.Ukr" />,
    value: 'uk',
    icon: ukFlag
  },
  {
    label: <FormattedMessage id="LanguagePopover.En" />,
    value: 'en',
    icon: enFlag
  }
];

export default function LanguagePopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { i18n, onChangeLocalization } = useConfig();

  const currentLang = useMemo(() => allLang.find((_lang) => _lang.value === i18n) || allLang[1], [i18n]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeLang = (value: any) => {
    onChangeLocalization(value);
    handleClose();
  };

  return (
    <>
      <IconButton
        shape="rounded"
        color="secondary"
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ml: 2,
          bgcolor: open ? 'grey.300' : 'transparent',
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: 'rgba(22, 28, 36, 0.6)'
            }
          })
        }}
      >
        <img src={currentLang.icon} alt="Language icon" />
      </IconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ p: 1 }}>
        {allLang.map((option: any) => (
          <MenuItem
            key={option.value}
            disabled
            selected={option.value === currentLang.value}
            onClick={() => handleChangeLang(option.value)}
            sx={{
              py: 1,
              px: 2.5,
              borderRadius: '6px',
              '&.Mui-selected': {
                '&:hover': {
                  bgcolor: 'grey.200'
                },
                bgcolor: 'grey.0'
              }
            }}
          >
            <ListItemIcon>
              <Box component="img" alt={option.label} src={option.icon} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{option.label}</ListItemText>
          </MenuItem>
        ))}
      </MenuPopover>
    </>
  );
}
