import { createSlice } from '@reduxjs/toolkit';

// project imports
import { SnackbarProps } from '../../models/snackbar';

const initialState: SnackbarProps = {
  open: false,
  message: 'Нотатку заархівовано',
  variant: 'success',
  close: true
};

// ==============================|| SLICE - SNACKBAR ||============================== //

const snackbar = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    openSnackbar(state, action) {
      const { open, message, variant, close } = action.payload;
      state.open = open || initialState.open;
      state.message = message || initialState.message;
      state.variant = variant || initialState.variant;
      state.close = close === false ? close : initialState.close;
    },

    closeSnackbar(state) {
      state.open = false;
    }
  }
});

export default snackbar.reducer;

export const { closeSnackbar, openSnackbar } = snackbar.actions;
