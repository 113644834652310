import { Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - TABLE CELL ||============================== //

export default function TableHead(theme: Theme) {
  return {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#185C8A',
          '& .MuiTableCell-root': {
            paddingTop: 7,
            paddingBottom: 7,

            '&:last-of-type': {
              paddingRight: 24,
              borderTopRightRadius: 8,
              borderBottomRightRadius: 8
            },
            '&:first-of-type': {
              paddingLeft: 24,
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8
            }
          }
        }
      }
    }
  };
}
