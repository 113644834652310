import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Box, Container, Toolbar } from '@mui/material';

// project imports
import Header from './Header';
import SideBar from './SideBar';
import { useDispatch } from '../store';
import { openSideBar } from '../store/slices/menu';

const Layout = () => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(openSideBar(!matchDownMD));
  }, [matchDownMD, dispatch]);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Header />
      <SideBar />
      <Box
        component="main"
        sx={{ width: 'calc(100% - 280px)', backgroundColor: '#F7F9FA', flexGrow: 1, p: { xs: 2, sm: 3 } }}
      >
        <Toolbar />
        {true && (
          <Container
            maxWidth="xl"
            sx={{
              px: { xs: 0, sm: 2 },
              position: 'relative',
              minHeight: 'calc(100vh - 110px)',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Outlet />
          </Container>
        )}
      </Box>
    </Box>
  );
};
export default Layout;
