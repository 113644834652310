import { useEffect } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// project imports
import Routes from './routes';
import ThemeWrapper from './themes';
import { checkAuth } from './utils/auth';
import ScrollTop from './utils/scrollTop';
import Locales from './components/Locales';
import { getProfile } from './store/actions/user';
import { useDispatch, useSelector } from './store';
import LoadingScreen from './components/LoadingScreen';
import Snackbar from './components/@extended/Snackbar';

function App() {
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.user);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  });

  useEffect(() => {
    checkAuth();
    dispatch(getProfile());
  }, [dispatch]);

  if (!isAuthenticated) return null;

  if (isLoading) return <LoadingScreen />;

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeWrapper>
        <Locales>
          <ScrollTop>
            <>
              <Routes />
              <Snackbar />
            </>
          </ScrollTop>
        </Locales>
      </ThemeWrapper>
    </QueryClientProvider>
  );
}

export default App;
