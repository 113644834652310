import { ReactNode, useEffect, useState } from 'react';
import { IntlProvider, MessageFormatElement } from 'react-intl';

// project imports
import { I18n } from '../models/config';
import useConfig from '../hooks/useConfig';

// load locales files
const loadLocaleData = (locale: I18n) => {
  switch (locale) {
    case 'uk':
      return import('../locales/uk.json');
    case 'en':
    default:
      return import('../locales/en.json');
  }
};

interface Props {
  children: ReactNode;
}

const Locales = ({ children }: Props) => {
  const { i18n } = useConfig();

  const [messages, setMessages] = useState<
    Record<string, string> | Record<string, MessageFormatElement[]> | undefined
  >();

  useEffect(() => {
    loadLocaleData(i18n).then(
      (d: { default: Record<string, string> | Record<string, MessageFormatElement[]> | undefined }) => {
        setMessages(d.default);
      }
    );
  }, [i18n]);

  return (
    <>
      {messages && (
        <IntlProvider locale={i18n} defaultLocale="uk" messages={messages}>
          {children}
        </IntlProvider>
      )}
    </>
  );
};

export default Locales;
