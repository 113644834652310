import { SyntheticEvent } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import MuiSnackbar from '@mui/material/Snackbar';
import { Alert, Fade, Grow, Slide, SlideProps } from '@mui/material';

// project imports
import IconButton from './IconButton';
import { KeyedObject } from '../../models/root';
import { useDispatch, useSelector } from '../../store';
import { closeSnackbar } from '../../store/slices/snackbar';

// animation function
function TransitionSlideLeft(props: SlideProps) {
  return <Slide {...props} direction="left" />;
}

function TransitionSlideUp(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

function TransitionSlideRight(props: SlideProps) {
  return <Slide {...props} direction="right" />;
}

function TransitionSlideDown(props: SlideProps) {
  return <Slide {...props} direction="down" />;
}

function GrowTransition(props: SlideProps) {
  return <Grow {...props} />;
}

// animation options
const animation: KeyedObject = {
  SlideLeft: TransitionSlideLeft,
  SlideUp: TransitionSlideUp,
  SlideRight: TransitionSlideRight,
  SlideDown: TransitionSlideDown,
  Grow: GrowTransition,
  Fade
};

// ==============================|| SNACKBAR ||============================== //

const Snackbar = () => {
  const dispatch = useDispatch();
  const snackbar = useSelector((state) => state.snackbar);
  const { close, message, open, variant } = snackbar;

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(closeSnackbar());
  };

  return (
    <>
      {/* success snackbar */}
      {variant === 'success' && (
        <MuiSnackbar
          TransitionComponent={animation.SlideLeft}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={open}
          autoHideDuration={1500}
          onClose={handleClose}
        >
          <Alert
            variant="filled"
            sx={{ alignItems: 'center', bgcolor: '#19B269' }}
            action={
              <>
                {close !== false && (
                  <IconButton
                    sx={{
                      backgroundColor: 'transparent',
                      '&:hover': { backgroundColor: 'transparent' }
                    }}
                    size="small"
                    aria-label="close"
                    variant="text"
                    onClick={handleClose}
                  >
                    <CloseOutlined />
                  </IconButton>
                )}
              </>
            }
          >
            {message}
          </Alert>
        </MuiSnackbar>
      )}
      {/* alert snackbar */}
      {variant === 'alert' && (
        <MuiSnackbar
          TransitionComponent={animation.SlideLeft}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            variant="filled"
            severity="error"
            sx={{ alignItems: 'center', whiteSpace: 'break-spaces' }}
            action={
              <>
                {close !== false && (
                  <IconButton
                    sx={{
                      backgroundColor: 'transparent',
                      '&:hover': { backgroundColor: 'transparent' }
                    }}
                    size="small"
                    aria-label="close"
                    variant="text"
                    onClick={handleClose}
                  >
                    <CloseOutlined />
                  </IconButton>
                )}
              </>
            }
          >
            {message}
          </Alert>
        </MuiSnackbar>
      )}
    </>
  );
};

export default Snackbar;
