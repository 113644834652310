import { createSlice } from '@reduxjs/toolkit';

interface MenuState {
  sideBarOpen: boolean;
}

const initialState: MenuState = {
  sideBarOpen: false
};

export const menu = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    // OPEN SIDEBAR
    openSideBar(state, action) {
      state.sideBarOpen = action.payload;
    }
  }
});

export default menu.reducer;

export const { openSideBar } = menu.actions;
