import { createSlice } from '@reduxjs/toolkit';

// project imports
import { IUserProfile, INotificationItem } from '../../models/user';

interface UserState {
  isLoading: boolean;
  error: object | string | null;
  userProfile: IUserProfile | null;
  notifications: INotificationItem[] | null;
}

const initialState: UserState = {
  isLoading: false,
  error: null,
  userProfile: null,
  notifications: null
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // END LOADING
    endLoading(state) {
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    // GET PROFILE
    getProfileSuccess(state, action) {
      state.userProfile = action.payload;
    },
    // GET TIMESHEE TNOTIFICATION
    getTimesheetNotificationSuccess(state, action) {
      state.notifications = action.payload?.items;
    }
  }
});

export const userActions = userSlice.actions;
export default userSlice.reducer;
