import { ListItemText } from '@mui/material';

interface Props {
  data: any;
}

export default function NotificationItem({ data }: Props) {
  return (
    <ListItemText
      disableTypography
      primary={data?.description}
      sx={{
        px: 2,
        py: 1,
        m: '4px 0',
        lineHeight: 'normal',
        fontSize: '16px',
        fontWeight: 400,
        color: '#0F1621',
        borderRadius: 1,
        background: data?.isRead ? 'initial' : '#FFE8E8'
      }}
    />
  );
}
