import { createContext, ReactNode } from 'react';

// project imports
import config from '../config';
import useLocalStorage from '../hooks/useLocalStorage';
import { CustomizationProps, I18n } from '../models/config';

// initial state
const initialState: CustomizationProps = {
  ...config,
  onChangeLocalization: (lang: I18n) => {}
};

const ConfigContext = createContext(initialState);

type ConfigProviderProps = {
  children: ReactNode;
};

function ConfigProvider({ children }: ConfigProviderProps) {
  const [config, setConfig] = useLocalStorage('mxp-react-ts-config', initialState);

  const onChangeLocalization = (lang: I18n) => {
    setConfig({
      ...config,
      i18n: lang
    });
  };

  return (
    <ConfigContext.Provider
      value={{
        ...config,
        onChangeLocalization
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
}

export { ConfigProvider, ConfigContext };
