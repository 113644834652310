import { combineReducers } from '@reduxjs/toolkit';

// project imports
import userReducer from './slices/user';
import menuReducer from './slices/menu';
import snackbarReducer from './slices/snackbar';
import uploadPlanReducer from './slices/uploadPlan';

const rootReducer = combineReducers({
  user: userReducer,
  menu: menuReducer,
  snackbar: snackbarReducer,
  uploadPlan: uploadPlanReducer
});

export default rootReducer;
