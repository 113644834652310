import { ConfigProps } from './models/config';

const config: ConfigProps = {
  defaultPath: '/',
  sideBarWidth: 280,
  i18n: 'uk',
  fontFamily: `'Gilroy', sans-serif`
};

export default config;
