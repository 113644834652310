import { createSlice } from '@reduxjs/toolkit';

// project imports
import { IUploadedPlan } from '../../models/uploadPlan';

interface UploadPlanState {
  isLoading: boolean;
  error: object | string | null;
  planningFiles: IUploadedPlan | null;
}

const initialState: UploadPlanState = {
  isLoading: false,
  error: null,
  planningFiles: null
};

export const uploadPlanSlice = createSlice({
  name: 'uploadPlan',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // END LOADING
    endLoading(state) {
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    // GET PLANNING FILES
    getPlanningFilesSuccess(state, action) {
      state.planningFiles = action.payload;
    }
  }
});

export const uploadPlanActions = uploadPlanSlice.actions;
export default uploadPlanSlice.reducer;
