import { Divider } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Box, Typography } from '@mui/material';
import { useTheme, styled, Theme } from '@mui/material/styles';

// project imports
import LogoSection from '../../../components/logo';
import { IUserProfile } from '../../../models/user';

interface StyleProps {
  theme: Theme;
  open: boolean;
}

const LogoWrapper = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }: StyleProps) => ({
  ...theme.mixins.toolbar,
  display: 'flex',
  alignItems: 'center',
  justifyContent: open ? 'flex-start' : 'center',
  padding: 0
}));
interface Props {
  open: boolean;
  user: IUserProfile | null;
}

const SideBarHeader = ({ open, user }: Props) => {
  const theme = useTheme();
  const fullName = `${user?.surname} ${user?.name} ${user?.fatherName || ''}`;

  return (
    <>
      <div>
        <Box sx={{ p: open ? '16px 24px' : '1px 12px 24px' }}>
          <LogoWrapper theme={theme} open={open}>
            <LogoSection isIcon={!open} sx={{ width: open ? 'auto' : 35, height: 35 }} />
          </LogoWrapper>
        </Box>
        {open && (
          <Box sx={{ px: 2 }}>
            <Box
              sx={{
                alignItems: 'start',
                backgroundColor: '#184868',
                px: 3,
                py: '11px',
                borderRadius: 2
              }}
            >
              <Typography sx={{ whiteSpace: 'break-spaces' }} color="#fff" variant="h4">
                {user?.name ? fullName : 'ПІБ'}
              </Typography>
              <Typography color="#9CA3AF" variant="h5">
                {user?.isAdmin ? (
                  <FormattedMessage id="Sidebar.Role.Admin" />
                ) : (
                  <FormattedMessage id="Sidebar.Role.Master" />
                )}
              </Typography>
            </Box>
          </Box>
        )}
      </div>
      {open && (
        <Divider
          sx={{
            borderColor: '#3F5E73',
            my: 3
          }}
        />
      )}
    </>
  );
};

export default SideBarHeader;
