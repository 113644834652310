export default function CssBaseline() {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        'html, body': {
          fontFamily: `'Gilroy','Roboto',sans-serif`
        }
      }
    }
  };
}
