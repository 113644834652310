import { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Badge, Dialog, Stack, Button, Grid, DialogTitle, Divider, DialogContent, DialogActions } from '@mui/material';

// project imports
import { useDispatch } from '../../../../store';
import NotificationList from './NotificationList';
import ScrollY from '../../../../components/ScrollY';
import { INotificationItem } from '../../../../models/user';
import { BellIcon } from '../../../../assets/images/icons/bell';
import { readNotifications } from '../../../../store/actions/user';
import IconButton from '../../../../components/@extended/IconButton';

interface Props {
  data: INotificationItem[] | null;
}

export default function NotificationsPopover({ data }: Props) {
  const anchorRef = useRef(null);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  // Group items by date
  const groupedItems = data?.reduce((acc: any, item) => {
    const date = item.created.split('T')[0]; // Extract date without time
    if (!acc[date]) {
      acc[date] = { date, items: [] };
    }
    acc[date].items.push(item);
    return acc;
  }, {});

  // Convert the grouped items object into an array
  const notificationList = (groupedItems && Object.values(groupedItems)) || [];

  const notReaded = data?.some((elem) => !elem.isRead);

  const handleRead = () => {
    if (!notReaded) return;
    else {
      const unreadIds = data?.filter((item) => !item.isRead).map((item) => item.id);
      unreadIds?.length && dispatch(readNotifications(unreadIds));
    }
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    handleRead();
    setOpen(!open);
  };

  return (
    <>
      <IconButton
        shape="rounded"
        color="secondary"
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 40,
          height: 40,
          bgcolor: open ? 'grey.300' : 'transparent',
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: 'rgba(22, 28, 36, 0.6)'
            }
          })
        }}
      >
        <Badge
          color={notReaded ? 'error' : 'default'}
          badgeContent=""
          sx={{
            '& .MuiBadge-badge': {
              minWidth: '15px',
              height: '15px',
              right: '4px',
              top: '4px'
            }
          }}
          children={<BellIcon />}
        />
      </IconButton>
      <Dialog fullWidth sx={{ '& .MuiDialog-paper': { maxWidth: '770px', p: 0 } }} open={open}>
        <DialogTitle sx={{ fontSize: '20px', color: '#185C8A', fontWeight: 700 }}>
          <FormattedMessage id="NotificationsPopover.Title" />
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ p: 3, maxHeight: 580 }}>
          <ScrollY>
            {notificationList?.map((item: any, index: number) => (
              <NotificationList key={index} data={item} />
            ))}
          </ScrollY>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ p: 2.5 }}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item>
              <Stack direction="row" spacing={2} alignItems="center">
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  onClick={handleClose}
                  sx={{
                    color: '#fff',
                    fontFamily: 'Gilroy',
                    backgroundColor: '#0F3D5C',
                    fontWeight: 700,
                    textTransform: 'none',
                    borderRadius: '8px',
                    width: '160px',
                    height: '40px'
                  }}
                >
                  <FormattedMessage id="NotificationsPopover.Btn.Exit" />
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}
