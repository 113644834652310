import { FormattedMessage } from 'react-intl';

// project imports
import { NavItemGroup } from '../../models/menu';
import { HomeIcon } from '../../assets/images/icons/home';
import { UsersIcon } from '../../assets/images/icons/users';
import { EditListIcon } from '../../assets/images/icons/editList';
import { CheckListIcon } from '../../assets/images/icons/checkList';

const admin: NavItemGroup = {
  id: 'group-admin',
  title: 'admin',
  children: [
    {
      id: 'home',
      title: <FormattedMessage id="Sidebar.NavItem.Home" />,
      url: '/',
      icon: <HomeIcon fontSize="small" />
    },
    {
      id: 'employees',
      title: <FormattedMessage id="Sidebar.NavItem.Employees" />,
      url: '/employees',
      icon: <UsersIcon fontSize="small" />
    },
    {
      id: 'admin',
      title: <FormattedMessage id="Sidebar.NavItem.Admin" />,
      url: '/admin/specialties',
      icon: <EditListIcon fontSize="small" />
    },
    {
      id: 'access',
      title: <FormattedMessage id="Sidebar.NavItem.Access" />,
      url: '/access',
      icon: <CheckListIcon fontSize="small" />
    }
  ]
};

export default admin;
