import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

// project imports
import Layout from '../layout';
import Loadable from '../components/Loadable';

// home page routing
const HomePage = Loadable(lazy(() => import('../pages/HomePage')));

// planning routing
const Planning = Loadable(lazy(() => import('../pages/planning/Planning')));
const PlanningUploadPlan = Loadable(lazy(() => import('../pages/planning/UploadPlan')));
const PlanningUnitList = Loadable(lazy(() => import('../pages/planning/UnitList')));
const PlanningDepartmentList = Loadable(lazy(() => import('../pages/planning/DepartmentList')));
const PlanningTechOperationsList = Loadable(lazy(() => import('../pages/planning/TechOperationsList')));
const PlanningTechOperation = Loadable(lazy(() => import('../pages/planning/TechOperation')));
const PlanningAddEmployee = Loadable(lazy(() => import('../pages/planning/AddEmployee')));

// timesheet routing
const TimeSheetUnitList = Loadable(lazy(() => import('../pages/timesheet/UnitList')));
const TimeSheetDepartmentList = Loadable(lazy(() => import('../pages/timesheet/DepartmentList')));
const TimeSheetAbsentEmployees = Loadable(lazy(() => import('../pages/timesheet/absent-employees/AbsentEmployees')));

// Work in progress
const TimeSheetAbsentEmployee = Loadable(lazy(() => import('../pages/timesheet/absent-employees/employee/Employee')));
const TimeSheetAbsentEmployeeTabAbsence = Loadable(
  lazy(() => import('../pages/timesheet/absent-employees/employee/TabAbsence'))
);

const TimeSheet = Loadable(lazy(() => import('../pages/timesheet/TimeSheet')));
const TimeSheetAddEmployee = Loadable(lazy(() => import('../pages/timesheet/AddEmployee')));
const TimeSheetEmployee = Loadable(lazy(() => import('../pages/timesheet/employee/Employee')));
const TimeSheetEmployeeTabTransfer = Loadable(lazy(() => import('../pages/timesheet/employee/TabTransfer')));
const TimeSheetEmployeeTabAbsence = Loadable(lazy(() => import('../pages/timesheet/employee/TabAbsence')));
const TimeSheetEmployeeTransferUpdate = Loadable(lazy(() => import('../pages/timesheet/employee/TransferUpdate')));
const TimeSheetEmployeeTransferEdit = Loadable(lazy(() => import('../pages/timesheet/employee/TransferEdit')));

// employees routing
const EmployeeList = Loadable(lazy(() => import('../pages/employees/employee-list/ListView')));
const Employee = Loadable(lazy(() => import('../pages/employees/employee/Employee')));
const EmployeeTabDetails = Loadable(lazy(() => import('../pages/employees/employee/TabDetails')));
const EmployeeTabVacations = Loadable(lazy(() => import('../pages/employees/employee/TabVacations')));
const EmployeeVacationEdit = Loadable(lazy(() => import('../pages/employees/employee/VacationEdit')));
const EmployeeVacationUpdate = Loadable(lazy(() => import('../pages/employees/employee/VacationUpdate')));
const EmployeeQualificationUpdate = Loadable(lazy(() => import('../pages/employees/employee/QualificationUpdate')));

// admin routing
const Admin = Loadable(lazy(() => import('../pages/admin/Admin')));
const AdminTabSpecialties = Loadable(lazy(() => import('../pages/admin/TabSpecialties')));
const AdminTabWorkSchedule = Loadable(lazy(() => import('../pages/admin/TabWorkSchedule')));
const AdminTabTimeTable = Loadable(lazy(() => import('../pages/admin/TabTimeTable')));
const AdminTabConstant = Loadable(lazy(() => import('../pages/admin/TabConstant')));
const AdminTimeTableEdit = Loadable(lazy(() => import('../pages/admin/TimeTableEdit')));
const AdminTimeTableUpdate = Loadable(lazy(() => import('../pages/admin/TimeTableUpdate ')));
const AdminSpecialtiesEdit = Loadable(lazy(() => import('../pages/admin/SpecialtiesEdit')));
const AdminSpecialtiesUpdate = Loadable(lazy(() => import('../pages/admin/SpecialtiesUpdate')));
const AdminWorkScheduleEdit = Loadable(lazy(() => import('../pages/admin/WorkScheduleEdit')));
const AdminWorkScheduleUpdate = Loadable(lazy(() => import('../pages/admin/WorkScheduleUpdate')));

// access control routing
const Access = Loadable(lazy(() => import('../pages/access/Access')));

export default function AppRoutes() {
  return useRoutes([
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          path: '/',
          element: <HomePage />
        },
        // planning
        {
          path: 'planning',
          element: <Planning />
        },
        {
          path: '/planning/upload',
          element: <PlanningUploadPlan />
        },
        {
          path: '/planning/units',
          element: <PlanningUnitList />
        },
        {
          path: '/planning/units/:unitId',
          element: <PlanningDepartmentList />
        },
        {
          path: '/planning/units/:unitId/departments/:departmentId',
          element: <PlanningTechOperationsList />
        },
        {
          path: '/planning/units/:unitId/departments/:departmentId/tech-operation/:planningRecordId',
          element: <PlanningTechOperation />
        },
        {
          path: '/planning/units/:unitId/departments/:departmentId/tech-operation/:planningRecordId/add-employee/:date',
          element: <PlanningAddEmployee />
        },

        // timesheet
        {
          path: '/timesheet/units',
          element: <TimeSheetUnitList />
        },
        {
          path: '/timesheet/units/:unitId',
          element: <TimeSheetDepartmentList />
        },
        {
          path: '/timesheet/units/:unitId/absent-employees',
          element: <TimeSheetAbsentEmployees />
        },
        {
          path: '/timesheet/units/:unitId/absent-employees/:employeeId/:sheetDate',
          element: <TimeSheetAbsentEmployee />,
          children: [
            {
              path: 'absence',
              element: <TimeSheetAbsentEmployeeTabAbsence />
            }
          ]
        },

        {
          path: '/timesheet/units/:unitId/departments/:departmentId/employees',
          element: <TimeSheet />
        },
        {
          path: '/timesheet/units/:unitId/departments/:departmentId/employees/:timesheetId/add-employee/:sheetDate',
          element: <TimeSheetAddEmployee />
        },
        {
          path: '/timesheet/units/:unitId/departments/:departmentId/employees/:employeeId/:sheetDate',
          element: <TimeSheetEmployee />,
          children: [
            {
              path: 'transfer',
              element: <TimeSheetEmployeeTabTransfer />
            },
            {
              path: 'absence',
              element: <TimeSheetEmployeeTabAbsence />
            }
          ]
        },
        {
          path: '/timesheet/units/:unitId/departments/:departmentId/employees/:employeeId/:sheetDate/transfer/update',
          element: <TimeSheetEmployeeTransferUpdate />
        },
        {
          path: '/timesheet/units/:unitId/departments/:departmentId/employees/:employeeId/:sheetDate/transfer/:recordId/edit',
          element: <TimeSheetEmployeeTransferEdit />
        },
        // employees
        {
          path: 'employees',
          element: <EmployeeList />
        },
        {
          path: '/employees/:employeeId',
          element: <Employee />,
          children: [
            {
              path: 'details',
              element: <EmployeeTabDetails />
            },
            {
              path: 'vacations',
              element: <EmployeeTabVacations />
            }
          ]
        },
        {
          path: 'employees/:employeeId/vacation/:vacationId/edit',
          element: <EmployeeVacationEdit />
        },
        {
          path: 'employees/:employeeId/vacation/update',
          element: <EmployeeVacationUpdate />
        },

        {
          path: 'employees/:employeeId/qualification/update',
          element: <EmployeeQualificationUpdate />
        },

        // admin
        {
          path: '/admin',
          element: <Admin />,
          children: [
            {
              path: 'specialties',
              element: <AdminTabSpecialties />
            },
            {
              path: 'work-schedule',
              element: <AdminTabWorkSchedule />
            },
            {
              path: 'time-table',
              element: <AdminTabTimeTable />
            },
            {
              path: 'constant',
              element: <AdminTabConstant />
            }
          ]
        },
        {
          path: 'admin/specialties/:hierarchyId/edit',
          element: <AdminSpecialtiesEdit />
        },
        {
          path: 'admin/specialties/update',
          element: <AdminSpecialtiesUpdate />
        },
        {
          path: 'admin/time-table/:timetableId/edit',
          element: <AdminTimeTableEdit />
        },
        {
          path: 'admin/time-table/update',
          element: <AdminTimeTableUpdate />
        },
        {
          path: 'admin/work-schedule/:workScheduleId/edit',
          element: <AdminWorkScheduleEdit />
        },
        {
          path: 'admin/work-schedule/update',
          element: <AdminWorkScheduleUpdate />
        },
        // access
        {
          path: '/access',
          element: <Access />
        }
      ]
    }
  ]);
}
