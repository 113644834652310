import { format } from 'date-fns';
import { List, ListSubheader } from '@mui/material';

// project imports
import NotificationItem from './NotificationItem';
import { INotificationItem } from '../../../../models/user';

interface Props {
  data: any;
}

export default function NotificationList({ data }: Props) {
  return (
    <List
      disablePadding
      subheader={
        <ListSubheader
          disableSticky
          sx={{ px: 2, py: 1, lineHeight: 'normal', fontSize: '16px', color: '#9BA3B3', fontWeight: 400 }}
        >
          {data?.date && format(new Date(data?.date), 'dd.MM.yyyy')}
        </ListSubheader>
      }
    >
      {data?.items?.map((item: INotificationItem, index: number) => (
        <NotificationItem key={item.created + index} data={item} />
      ))}
    </List>
  );
}
