// project imports
import Default from './default';
import { PresetColor } from '../../models/config';
import { PalettesProps } from '@ant-design/colors';
import { PaletteThemeProps } from '../../models/theme';

const Theme = (colors: PalettesProps, presetColor: PresetColor): PaletteThemeProps => {
  switch (presetColor) {
    default:
      return Default(colors);
  }
};

export default Theme;
