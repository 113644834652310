import { useEffect } from 'react';
import { Box } from '@mui/material';

// project imports
import Profile from './Profile';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';
import { useDispatch, useSelector } from '../../../store';
import { getTimesheetNotification } from '../../../store/actions/user';

const HeaderContent = () => {
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getTimesheetNotification());
    const intervalId = setInterval(() => {
      dispatch(getTimesheetNotification());
    }, 60000);

    // Clear the timeout when the component unmounts or as needed
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box sx={{ width: '100%', ml: 1 }} />
      {(notifications?.length && <NotificationsPopover data={notifications} />) || <></>}
      <LanguagePopover />
      <Profile />
    </>
  );
};

export default HeaderContent;
