import { BrowserCacheLocation } from '@azure/msal-browser';
import { PublicClientApplication } from '@azure/msal-browser';

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: '2fded152-beed-4433-88d2-68f71482b27c',
    authority: 'https://login.microsoftonline.com/0eeb54ec-50a1-4ef3-8bda-96b20dcc413c',
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: false
  }
};

export const accessTokenRequest = {
  scopes: ['api://8fab08b9-a0ac-40cf-9bc1-1a40a43a7866/api.scope']
};

export const msalInstance = new PublicClientApplication(msalConfig);
