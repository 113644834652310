import axiosLib from 'axios';
import { checkAuth, login } from '../utils/auth';

const axios = axiosLib.create({
  baseURL: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_ENV_API_URL : 'https://mhp-personalplanning-api-prod.azurewebsites.net',
  headers: { 'content-type': 'application/json' }
});

// REQUEST
axios.interceptors.request.use(
  async (config: any) => {
    const result = await checkAuth();

    // Check if the header property exists
    if (config.headers) {
      // Set the Authorization header if it exists
      config.headers['Authorization'] = `Bearer ${result?.accessToken}`;
    } else {
      // Create the headers property if it does not exist
      config.headers = {
        Authorization: `Bearer ${result?.accessToken}`
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// RESPONSE
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      login();
    }
    // User does not have permission to access
    if (error.response.status === 403) {
      localStorage.clear();
      login();
    }
    return Promise.reject(error);
  }
);

export { axios };
